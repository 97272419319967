var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[(_vm.rol != 'root')?_c('v-container',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"80vh","width":"80vw"}},[_c('v-card',{staticStyle:{"border-radius":"20px"},attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":'/static/icon/peligro.svg'}})])]),_c('v-card-text',{staticClass:"titleModal text-center"},[_vm._v(" Esta vista no esta disponible para este rol de usuario. ")]),_c('v-card-actions',{staticClass:"d-flex align-center justify-center card_accion"},[_c('v-btn',{staticClass:"btnGuardar",on:{"click":function($event){return _vm.redireccionar()}}},[_vm._v(" Ok ")])],1)],1)],1):_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('data-table',{ref:"tableVistas",attrs:{"tableName":'Vistas del sistema',"url":_vm.url,"columns":_vm.columns,"filters":_vm.filters,"perPage":[10, 25, 50, 100]},on:{"setFilters":_vm.setFilters},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',[_vm._v(_vm._s(item.nombre))])]),_c('td',[_c('div',[_vm._v(_vm._s(item.padre))])]),_c('td',[_c('div',{staticClass:"tblCenter"},[_vm._v(_vm._s(item.sistema))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","depressed":"","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item.id, item.nombre)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"rounded":"","color":"#004BAF","right":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v("Agregar "),_c('div',{staticClass:"mr-4"})],1)],1)]),_c('template',{slot:"filters"},[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.tipos,"item-text":"tipo","item-value":"id","label":"Tipo","persistent-hint":""},model:{value:(_vm.advancedSearch.tipo),callback:function ($$v) {_vm.$set(_vm.advancedSearch, "tipo", $$v)},expression:"advancedSearch.tipo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.padres,"item-text":"nombre","item-value":"valor","label":"Padre","persistent-hint":""},model:{value:(_vm.advancedSearch.padre),callback:function ($$v) {_vm.$set(_vm.advancedSearch, "padre", $$v)},expression:"advancedSearch.padre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('v-text-field',{staticClass:"estiloLetras",attrs:{"outlined":"","label":"Nombre"},model:{value:(_vm.advancedSearch.nombre),callback:function ($$v) {_vm.$set(_vm.advancedSearch, "nombre", $$v)},expression:"advancedSearch.nombre"}})],1)],1)],2)],1)],1)],1),[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":_vm.imagenModal}}),_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.titulo_modal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.finaliza_carga),expression:"!finaliza_carga"}],attrs:{"id":"loadingModal"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"blue"}})],1)]),_c('v-container',{attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-divider',{staticClass:"divider"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Nombre","error-messages":errors},model:{value:(_vm.vista.nombre),callback:function ($$v) {_vm.$set(_vm.vista, "nombre", $$v)},expression:"vista.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"URL","error-messages":errors},model:{value:(_vm.vista.url),callback:function ($$v) {_vm.$set(_vm.vista, "url", $$v)},expression:"vista.url"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Icono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"estiloLetras",attrs:{"outlined":"","label":"Icono","error-messages":errors},model:{value:(_vm.vista.icono),callback:function ($$v) {_vm.$set(_vm.vista, "icono", $$v)},expression:"vista.icono"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"estiloLetras",attrs:{"outlined":"","label":"Valor","error-messages":errors},model:{value:(_vm.vista.valor),callback:function ($$v) {_vm.$set(_vm.vista, "valor", $$v)},expression:"vista.valor"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sistema","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"estiloLetras",attrs:{"outlined":"","item-text":"nombre","item-value":"id","items":_vm.sistemas,"label":"Sistema","error-messages":errors},model:{value:(_vm.vista.sistema),callback:function ($$v) {_vm.$set(_vm.vista, "sistema", $$v)},expression:"vista.sistema"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"estiloLetras",attrs:{"outlined":"","items":_vm.tipos,"item-text":"tipo","item-value":"id","label":"Tipo","persistent-hint":"","error-messages":errors},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})]}}],null,true)})],1),(_vm.is_child)?[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Padre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"estiloLetras",attrs:{"outlined":"","items":_vm.padres,"item-text":"nombre","item-value":"valor","label":"Padre","persistent-hint":"","error-messages":errors},model:{value:(_vm.vista.padre),callback:function ($$v) {_vm.$set(_vm.vista, "padre", $$v)},expression:"vista.padre"}})]}}],null,true)})],1)]:_vm._e()],2)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"disabled":_vm.loading_guardar},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"disabled":invalid,"loading":_vm.loading_guardar},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }